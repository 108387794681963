// const NODE = 'https://mainnet.veblocks.net/'
// const NETWORK = 'main'
const NODE = 'https://mainnet.veblocks.net/'
const NETWORK = 'main'
const PROJECT_NAME = 'Snapshot'
const CONTRACT = '0x2f478c2e68e3385e632C625F0ee12D5a3A775e68'

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    CONTRACT,
}
